
.timer-container{
display: flex;
flex-direction: column;
text-align: left;
// margin-right: 80%;
float: left;
}

.current-timer{
    text-align: center;
    font-family: monospace;
    font-size: 30px;
    margin: auto;
    line-height: 3.3;
    width: 100px;
    height: 100px;
    border: 1px solid;
    border-radius: 100%;
    margin-bottom: 40px;
    margin-top: 40px;

}
.timer-controls{
    display: flex;
    justify-content: space-evenly;
    
}